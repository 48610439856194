.navbarcont
{
    width: 100vw;
    height: 70px;
    background-color: rgb(9, 9, 9);
    /* padding: 20px; */
    display: flex;
    align-items: center;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    justify-content: center;
}

.navbarinnercont{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbarinnercont span{
    color: white;
    font-size: 24px;
    margin-left: 10px;
}

.navlogindiv
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.navbarinnercont button{
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 2px;
    padding: 5px 30px;
    font-size: 18px;
    margin-left: 20px;
    cursor: pointer;
}

.titandlogo
{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbarinnercont button:hover
{

    background-color: #0084ff;

}